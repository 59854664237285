// out: false, main: ../style.scss

///////////////////////////////////////
/////////// Font Families /////////////
///////////////////////////////////////

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'roboto-slab';
  font-style: normal;
  font-weight: 400;
  src: url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('roboto-slab'), local('roboto-slab-v12-latin-regular'),
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-regular.svg#roboto-slab') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'roboto-slab';
  font-style: normal;
  font-weight: 700;
  src: url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('roboto-slab'), local('roboto-slab-v12-latin-700'),
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../font/roboto-slab-v12-latin-2/roboto-slab-v12-latin-700.svg#roboto-slab') format('svg'); /* Legacy iOS */
}

/* TheAntiquaSunOT */
@font-face {
  font-family: 'TheAntiquaSunOT';
  src: url('../font/The_Antiqua_Sun/TheAntiquaB-W5Plain.woff2');
}


/* Open Sans */
/* open-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../font/open-sans-webfont/open-sans-v35-latin-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

  