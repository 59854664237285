// out: false, main: ../style.scss
@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1);
}

.navigation-open {
  height: 100%;
  overflow: hidden;
}

#main-navigation {
  display: none;
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: $white;
  padding: 1rem;
  .navigation-open & {
    display: block;
  }
  a {
    color: $black;
    &:hover {
      text-decoration: none;
    }
  }
  .toggle-subnavi {
    position: absolute;
    right: calc(1rem + 4px);
    top: calc(1.5em + 6px);
    display: inline;
    border: 2px solid $font-color-active;
    border-width: 0 2px 2px 0;
    padding: 4px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: all .3s ease-out;
    margin: 0;
    &:focus {
      outline: none;
    }
  }
  // LVL1 Main Navigation
  .lvl-1 {
    &.entry {
      position: relative;
      padding: 1rem 0;
      .nav-link {
        padding: .375rem 1rem;
        display: inline-block;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 120%;
        font-weight: normal;
        &.lvl-1.active {
          color: $color-2;
        }
      }
      &.open-subnavi {
        background: $color-1;
        .lvl-1.nav-link.active {
          color: $color-1;
        }
        .lvl-2.nav-link.active {
          text-decoration: underline;
        }
        
        .subnavi {
          display: block;
        }
      }

      &.open {
        background: $color-1;
      }
      .subnavi {
        display: none;
      }
    }
  }
  // LVL2 Main Navigation
  .lvl-2 {
    &.entry {
      .nav-link {
        font-size: 1.1rem;
        padding: .35rem 1.25rem;
        margin: 0 0 .4rem 0;
      }
    }
  }
  .lvl-1.entry.open-subnavi, .lvl-2.entry.open-subnavi {
    >.toggle-subnavi {
      transform: rotate(-135deg);
      transition: all .3s ease-in;
    }
  }
  // LVL3 Main Navigation
  .subnavi.lvl-2 {
    display: block;
  }
}
@media screen and (min-width: $viewport-xl) {
  #main-navigation {
    display: block;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    padding: 0;
    background: none;
    text-align: right;
    ul.lvl-0 {
      display: flex;
      justify-content: space-between;
      border-top: none;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    // LVL1 Main Navigation
    .lvl-1 {
      text-align: left;
      &.entry {
        display: inline-block;
        border: none;
        padding: 0;
        .nav-link {
          font-size: 1rem;
          padding-top: .55rem;
          padding-bottom: .55rem;
          &.has-subnavi {
            &::after {
              content: '';
              display: inline-block;
              height: .375rem;
              width: .625rem;
              margin-left: .4rem;
              margin-top: -.3rem;
              vertical-align: middle;
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.97 5.27'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:#{url-friendly-colour($color-1)};%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-arrow-gray-down%3C/title%3E%3Cpolygon class='a' points='4.99 5.27 0 0.74 0.67 0 4.99 3.92 9.3 0 9.97 0.74 4.99 5.27'/%3E%3C/svg%3E") center center/contain no-repeat;
            }
          }
          &.active, &:hover {
            color: $color-2;
            &::after{
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.97 5.27'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:#{url-friendly-colour($color-2)};%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-arrow-gray-down%3C/title%3E%3Cpolygon class='a' points='4.99 5.27 0 0.74 0.67 0 4.99 3.92 9.3 0 9.97 0.74 4.99 5.27'/%3E%3C/svg%3E");
            }
          }
        }
        .toggle-subnavi {
          display: none;
        }
        &:hover, .open-subnavi {
          .subnavi.lvl-1 {
            display: block;
          }
        }
        &:last-child {
          .lvl-1.subnavi {
            right: 0;
          }
        }
      }
    }

    // LVL2 Main Navigation
    .subnavi.lvl-1 {
      position: absolute;
      background: $white;
      padding: .8rem .8rem .4rem 0;
      .entry {
        .nav-link {
          white-space: nowrap;
          padding-top: .375rem;
          padding-bottom: .375rem;
          &:hover, &.active {
            color: $color-1;
          }
        }
      }
    }
  }
}


/* BREADCRUMB NAVIGATION START */
#breadcrumb {
  display: block;
  font-size: .8rem;
  line-height: 140%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  a {
    padding:0;
    margin: 0 .4rem 0 0;
    color: $font-color;
    line-height: 140%;
    text-decoration:none;
    display:inline-block;
    font-weight:normal;
    &:hover {
      color: $color-2;
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5.27 9.97'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:#{url-friendly-colour($color-2)};stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-arrow-red-right%3C/title%3E%3Cpolyline class='a' points='0.37 0.34 4.6 4.99 0.37 9.64'/%3E%3C/svg%3E");
      }
    }
    &::before {
      content: '';
      display: inline-block;
      height: .4375rem;
      width: .3125rem;
      margin-right: .4rem;
      margin-top: -.1rem;
      vertical-align: middle;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5.27 9.97'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:#{url-friendly-colour($color-1)};stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-arrow-red-right%3C/title%3E%3Cpolyline class='a' points='0.37 0.34 4.6 4.99 0.37 9.64'/%3E%3C/svg%3E") center center/contain no-repeat;
    }
  }
  ul {
    list-style:none;
    padding:0;
    margin:0;
    display: inline-block;
  }
  li {
    display:inline-block;
    white-space:nowrap;
    &:hover .sub-navigation {
      display:block;
    }
  }
  .lvl-0 a::before {
    display: none;
  }
  .entry {
    position: relative;
    padding: 0;
    margin-right: 0;
    line-height: 120%;
    &:last-child {
      padding: 0;
    }
  }
  .sub-navigation {
    display:none;
    position:absolute;
    top:.8125rem;
    left:.3rem;
    background-color: $white;
    padding:.5rem .25rem .25rem .25rem;
    box-shadow: 0px 2px 2px rgb(209, 209, 209);
    z-index:1000;
    border:none;
    li {
      display: block;
    }
    a {
      padding:0 0 0 8px;
      text-transform: none;
      &::before {
        display: none;
      }
    }
  }
  .currentEntry {
    a {
      color: $color-2;
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5.27 9.97'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:#{url-friendly-colour($color-2)};stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-arrow-red-right%3C/title%3E%3Cpolyline class='a' points='0.37 0.34 4.6 4.99 0.37 9.64'/%3E%3C/svg%3E");
      }
    }
  }
}
/* BREADCRUMB NAVIGATION ENDE */
