// out: false, main: ../style.scss
///////////////////////////////////////
////////////// Form V1 ////////////////
///////////////////////////////////////
input {
  margin-bottom: 0; 
  width:100%; 
  color: $color-2;
  border: none;
  outline: none;
  .border-effect & { 
    border: 0; 
    border-bottom: 1px solid $color-2; 
    background: transparent; 
    padding-left: 0; 
    padding-right: 0;
  }
  ~ .focus-effect {
    position: absolute; 
    bottom: 0; 
    width: 0; 
    height: 2px; 
    background-color: $color-2; 
    transition: 0.4s;
    .left & {
      left: 0;
    }
    .spread & {
      left: 50%;
    }
  }

  &:focus {
    outline: none;
    ~ .focus-effect {
      .left &, .left.has-content & {
        width: 100%; transition: 0.4s;
      }
      .spread &, .spread.has-content & {
        left: 0%; width: 100%; transition: 0.4s;
      }
    }
  }
}
.form-v1 {
  // background: $color-2--l2;
  position: relative;
  label {
    line-height: 1.8em;
  }
  .form-field {
    margin-bottom: .5rem; 
    position: relative;
    &.select {
      .select-style {
        overflow: hidden;
        position: relative;
        background: $white;
        select {
          padding: .5rem;
          margin: 0;
          border: none;
          box-shadow: none;
          background: transparent;
          background-image: none;
          appearance: none;
          &:focus {
            outline: none;
          }
        }
        &::after {
          content: '';
          pointer-events: none;
          display: block;
          position: absolute;
          height: 100%;
          width: 50px;
          top:0;
          right: 0;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.97 5.27'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:#{url-friendly-colour($color-0--d2)};%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-arrow-gray-down%3C/title%3E%3Cpolygon class='a' points='4.99 5.27 0 0.74 0.67 0 4.99 3.92 9.3 0 9.97 0.74 4.99 5.27'/%3E%3C/svg%3E") center center/11px 6px no-repeat;
        }
      }
    }
  }
  a {
    color: $color-2;
    &.button {
      color: $white;
    }
  }
  .pretty {
    .state {
      label {
        &:before {
          background-color: $white;
          border-color: transparent;
        }
      }
    }
    &.p-icon  input:checked~ {
      .state {
        .icon {
          color: $color-2;
        }
        label {
          &:before {
            border-color: transparent;
          }
        }
      }
    }
  }
}

.drop-zone {
  position: relative;
  &--container {
    border: 2px dashed $color-1;
    min-height: 6.25rem;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .8rem;
    background: #fff;
    margin-bottom: 1rem;
  }
  &--filepick-label {
    display: block;
    color: $color-1;
    outline: 0;
    padding: .3rem .5rem;
    margin-bottom: .5rem;
    cursor: pointer;
    .default {
      display: inline;
    }
    .start, .over {
      display: none;
    }
  }
  &--filepick-input {
    display: none;
  }

  &.drag {
    &--start {
      .drop-zone {
        // &--container {
        //   border: 2px dashed $color-2;
        // }
        &--filepick-label {
          // color: $color-2;
          .start {
            display: inline;
          }
          .over, .default {
            display: none;
          }
        }
      }
    }
    &--over {
      .drop-zone {
        &--container {
          background: $color-1--l2;
        }
        &--filepick-label {
          // color: $color-3;
          .over {
            display: inline;
          }
          .start, .default {
            display: none;
          }
        }
      }
    }
  }

  &--message {
    display: none;
    &.error {
      display: block;
    }
  }

  .showfiles {
    li {
      padding: .4rem .8rem;
      margin: .5rem 0;
      background-color: $color--notice;
      color: $white;
      display: flex;
      justify-content: space-between;
      &.upload-success {
        background-color: $color--success;
      }
      &.upload-error {
        background-color: $color--error;
      }
      &.upload-loading {
        background-color: $color--warning;
        color: $font-color-0;
        .upload-status {
          animation: uploading 1.3s ease infinite;

        }
      }
      .upload-status {
        cursor: pointer;
      }
    }
  }
}

@keyframes uploading {
  0% {
    transform: translate(0, 15%);
  }
  70% {
    transform: translate(0, -15%);
  }
  90% {
    transform: translate(0, -15%);
  }
  100% {
    transform: translate(0, 15%);
  }
}