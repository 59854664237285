// out: false, main: ../style.scss

////////////////////////////////////////////
// ************* DEBUG MODE ************ //
//////////////////////////////////////////
$debug-mode: false !default;


// ANCHOR VIEWPORT

$viewport-m: 32rem !default;
$viewport-l: 48rem !default;
$viewport-xl: 64rem !default;


// ANCHOR MEASURES 

$measure--sm: $viewport-l;
$measure--md: $viewport-xl;
$measure--lg: 90rem;
$measure--xl: 111rem; /* 1776px */


// ANCHOR FONT-STYLES 

$font-size: 16px !default;
$font-weight: 300 !default;
$font-color: #000 !default;

$font-family--1: "roboto-slab", sans-serif !default;
%font-family--1-extras {
  text-transform: none;
  font-weight: 300;
}

$font-family--2: "roboto-slab", sans-serif !default;
%font-family--2-extras {
  text-transform: none;
}

$headline-1--min: 42px !default;
$headline-1--max: 52px !default;
$headline-1--line-height: 1.093em !default;
$headline-1--letter-spacing: -1.5px !default;

$headline-2--min: 34px !default;
$headline-2--max: 40px !default;
$headline-2--line-height: 1.212em !default;
$headline-2--letter-spacing: -0.5px !default;

$headline-3--min: 22px !default;
$headline-3--max: 26px !default;
$headline-3--line-height: 1.179em !default;
$headline-3--letter-spacing: 0 !default;

$headline-4--min: 18px !default;
$headline-4--max: 22px !default;
$headline-4--line-height: 1.2em !default;
$headline-4--letter-spacing: 0.25px !default;

$headline-5--min: 16px !default;
$headline-5--max: 18px !default;
$headline-5--line-height: 1.2em !default;
$headline-5--letter-spacing: 0 !default;

$headline-6--min: 14px !default;
$headline-6--max: 16px !default;
$headline-6--line-height: 1.2em !default;
$headline-6--letter-spacing: 0.15px !default;

$sub-1--min: 16px !default;
$sub-1--max: 18px !default;
$sub-1--line-height: 1.120em !default;
$sub-1--letter-spacing: 0.15px !default;

$sub-2--min: 14px !default;
$sub-2--max: 16px !default;
$sub-2--line-height: 1.333em !default;
$sub-2--letter-spacing: 0.1px !default;

$body-1--min: 16px !default;
$body-1--max: 17px !default;
$body-1--line-height: 1.400em !default;
$body-1--letter-spacing: 0.5px !default;

$body-2--min: 13px !default;
$body-2--max: 15px !default;
$body-2--line-height: 1.235em !default;
$body-2--letter-spacing: 0.45px !default;

$caption--min: 12px !default; 
$caption--max: 14px !default;
$caption--line-height: 1.235em !default;
$caption--letter-spacing: 0.4px !default;

$font-button--min: 16px !default;
$font-button--max: 18px !default;
$font-button--letter-spacing: 0.15px !default;

$font-button-medium--min: 11px !default;
$font-button-medium--max: 14px !default;
$font-button-medium--letter-spacing: 0.15px !default;

$font-button-small--min: 9px !default;
$font-button-small--max: 12px !default;
$font-button-small--letter-spacing: 0.15px !default;


// ANCHOR MELDUNGEN

$color--success: rgb(215, 237, 215) !default;
$color--warning: rgb(247, 241, 205) !default;
$color--error: rgb(244, 207, 205) !default;
$color--notice: rgb(213, 215, 217) !default;

$font-color--success: rgb(56, 72, 57) !default;
$font-color--warning: rgb(83, 80, 60) !default;
$font-color--error: rgb(136, 0, 0) !default;
$font-color--notice: rgb(95, 100, 104) !default;

$border--success: 1px solid rgb(159, 179, 159) !default;
$border--warning: 1px solid rgb(185, 180, 152) !default;
$border--error: 1px solid  rgb(183, 153, 151) !default;
$border--notice: 1px solid rgb(172, 175, 178) !default;


$padding-notification: 2rem !default;
$margin-notification: 0 auto !default;
$borderradius-notification: 5px !default;


// ANCHOR COLORS

$color-1: rgb(190, 190, 0) !default;
$color-1--l2: rgb(240, 241, 192) !default;
$color-1--l1: rgb(220, 220, 130) !default;
$color-1--d1: rgb(143, 133, 13) !default;
$color-1--d2: rgb(89, 83, 21) !default;

$color-2: rgb(187, 100, 145) !default;
$color-2--l2: rgb(251, 227, 232) !default;
$color-2--l1: rgb(245, 190, 200) !default;
$color-2--d1: rgb(120, 70, 110) !default;
$color-2--d2: rgb(97, 55, 89) !default;

$color-3: rgb(56, 178, 198) !default;
$color-3--l2: rgb(191, 234, 234) !default;
$color-3--l1: rgb(165, 210, 210) !default;
$color-3--d1: rgb(34, 118, 126) !default;
$color-3--d2: rgb(21, 83, 89) !default;

$color-4: rgb(180, 170, 150) !default;
$color-4--l2: rgb(245, 239, 225) !default;
$color-4--l1: rgb(225, 218, 200) !default;
$color-4--d1: rgb(112, 107, 98) !default;
$color-4--d2: rgb(82, 77, 67) !default;

$black: rgb(0, 0, 0) !default;
$white: rgb(255, 255, 255) !default;

$color-white: $white !default;
$color-0--l3: rgb(249, 249, 249) !default;
$color-0--l2: rgb(237, 237, 237) !default;
$color-0--l1: rgb(224, 224, 224) !default;
$color-0: rgb(204, 204, 204) !default;
$color-0--d1: rgb(171, 171, 171) !default;
$color-0--d2: rgb(128, 128, 128) !default;
$color-0--d3: rgb(71, 71, 71) !default;
$color-black: $black !default;


// Font Colors
$font-color-0: rgb(71, 71, 71);
$font-color-1: rgb(34, 118, 126);
$font-color-2: rgb(120, 70, 110);
$font-color-3: rgb(128,128,128);
$font-color-4: rgb(251, 227, 232);
$font-color-5: rgb(204,191,131); 

$font-color-active: rgb(240, 165, 0);
$font-color-active--hvr: rgb(197, 136, 0);

$font-color-black: rgb(0,0,0);
$font-color-white: rgb(255,255,255);

// Links, Buttons
$color-active: rgb(240, 165, 0);
$color-active--hvr: rgb(197, 136, 0);

// SECTION alte Farbdefinitionen - irgendwann löschen
$color-1--dark: $color-1--d1 !default;
$color-1--light: $color-1--l1 !default;

$color-2--dark: $color-2--d1 !default;
$color-2--light: $color-2--l1 !default;

$color-3--dark: $color-3--d1 !default;
$color-3--light: $color-3--l1 !default;

$color-4--dark: $color-4--d1 !default;
$color-4--light: $color-4--l1 !default;

$color-5: rgba(180, 170, 150, 1) !default;
$color-5--dark: rgba(112, 107, 98, 1) !default;
$color-5--light: rgba(225, 218, 200, 1) !default;

$dark-grey: $color-0--d2 !default;
$light-grey: $color-0--l2 !default;

// !SECTION 


// ANCHOR VERLÄUFE

$grad-1: linear-gradient(0deg, $color-1 33%, $color-1--l2 100%) !default;
$grad-2: linear-gradient(0deg, $color-2 33%, $color-2--l2 100%) !default;
$grad-3: linear-gradient(0deg, $color-3 33%, $color-3--l2 100%) !default;
$grad-4: linear-gradient(0deg, $color-4 33%, $color-4--l2 100%) !default;
$grad-5: linear-gradient(0deg, $color-1--d2 33%, $color-1 100%) !default;
$grad-6: linear-gradient(0deg, $color-2--d2 33%, $color-2 100%) !default;
$grad-7: linear-gradient(0deg, $color-3--d2 33%, $color-3 100%) !default;
$grad-8: linear-gradient(0deg, $color-4--d2 33%, $color-4 100%) !default;


// ANCHOR TABS

$tab-default-padding: 0.3em 1em !default;

$tab-solid-rounded--border-radius: 0 !default;
$tab-pill--border-radius: 50px !default;

$tab-solid--fill: $white !default;
$tab-solid--borderheight: 4px !default;

$tabcontent--fill: $white !default;

$tab-cube--height: 120px !default;
@function calc-tab-cube--halfheight($num, $neg: false) {
  @if $neg {
    @return $num / 2 * -1;
  }@else {
    @return $num / 2;
  }
}

$tabcontent-bordercolor-0: $color-0--l2 !default;
$tabcontent-bordercolor-1: $color-1--l2 !default;
$tabcontent-bordercolor-2: $color-2--l2 !default;
$tabcontent-bordercolor-3: $color-3--l2 !default;
$tabcontent-bordercolor-4: $color-4--l2 !default;
$tabcontent-bordercolor-5: $color-4--l2 !default;

$tabcolor-0--background: $color-0--l2 !default;
$tabcolor-1--background: $color-1--l2 !default;
$tabcolor-2--background: $color-2--l2 !default;
$tabcolor-3--background: $color-3--l2 !default;
$tabcolor-4--background: $color-4--l2 !default;
$tabcolor-5--background: $color-4--l2 !default;

$tabcolor-0--background-hover: $color-0--l1 !default;
$tabcolor-1--background-hover: $color-1--l1 !default;
$tabcolor-2--background-hover: $color-2--l1 !default;
$tabcolor-3--background-hover: $color-3--l1 !default;
$tabcolor-4--background-hover: $color-4--l1 !default;
$tabcolor-5--background-hover: $color-4--l1 !default;


// ANCHOR BUTTONS

$button-default-padding: 0.3em 1em !default;

$button-border-radius: 0 !default;

$button-fill: $color-active !default;
$button-fill--hover: $color-active--hvr !default;
$button-fill-disabled: $color-0--l3 !default;
$button-transition: 0.2s ease-in-out !default;

$button-solid--color: $black !default;
$button-solid--color-hover: $white !default;
$button-solid--color-disabled: $color-0--l1 !default;

$button-outline--fill: transparent !default;
$button-outline--color: $black !default;
$button-outline--color-hover: $white !default;
$button-outline--border: 2px solid $color-active !default;
$button-outline--border-hover: 2px solid transparent !default;
$button-outline--hover-fill: $color-active--hvr !default; 

$button-pill--border-radius: 50px !default;

$button-bubble-arrow-size: 10px !default;
// Button Icons
$button-icon-default-hover: false !default;
$button-icon-default-animation: ".hvr-forward" !default;
$button-icon-default-direction: "left" !default;


// ANCHOR PADDINGS | MARGINS

$default-p--i: 1.3rem !default;
$default-p--o: 0.7rem !default;

$p1: 1rem !default;
$p1--i: $default-p--i !default;
$p1--o: $default-p--o !default;

$p2: 2rem !default;
$p2--i: $default-p--i*1.5 !default;
$p2--o: $default-p--o*1.5 !default;

$p3: 3rem !default;
$p3--i: $default-p--i*2 !default;
$p3--o: $default-p--o*2 !default;

$p4: 4rem !default;
$p4--i: $default-p--i*2.5 !default;
$p4--o: $default-p--o*2.5 !default;
// $p1: 1rem !default;
// $p1--i: 1.3rem !default;
// $p1--o: 0.7rem !default;

// $p2: 2rem !default;
// $p2--i: 1.3rem !default;
// $p2--o: 0.7rem !default;

// $p3: 3rem !default;
// $p3--i: 1.3rem !default;
// $p3--o: 0.7rem !default;

// $p4: 4rem !default;
// $p4--i: 1.3rem !default;
// $p4--o: 0.7rem !default;

$m1: 1rem !default;
$m2: 2rem !default;
$m3: 3rem !default;
$m4: 4rem !default;


// ANCHOR TRENNER