// out: false, main: ../style.scss
/** Material design icons 4.0.0 */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  // src: url(../font/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(../font/MaterialIcons-Regular.woff2) format('woff2'),
    // url(../font/MaterialIcons-Regular.woff) format('woff'),
    url(../font/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  // src: url(../font/MaterialIconsOutlined-Regular.eot); /* For IE6-8 */
  src: local('Material Icons Outlined'),
    local('MaterialIconsOutlined-Regular'),
    url(../font/MaterialIconsOutlined-Regular.woff2) format('woff2'),
    // url(../font/MaterialIconsOutlined-Regular.woff) format('woff'),
    // url(../font/MaterialIconsOutlined-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  // src: url(../font/MaterialIconsRound-Regular.eot); /* For IE6-8 */
  src: local('Material Icons Round'),
    local('MaterialIconsRound-Regular'),
    url(../font/MaterialIconsRound-Regular.woff2) format('woff2'),
    // url(../font/MaterialIconsRound-Regular.woff) format('woff'),
    // url(../font/MaterialIconsRound-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  // src: url(../font/MaterialIconsSharp-Regular.eot); /* For IE6-8 */
  src: local('Material Icons Sharp'),
    local('MaterialIconsSharp-Regular'),
    url(../font/MaterialIconsSharp-Regular.woff2) format('woff2'),
    // url(../font/MaterialIconsSharp-Regular.woff) format('woff'),
    // url(../font/MaterialIconsSharp-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Two Tone';
  font-style: normal;
  font-weight: 400;
  // src: url(../font/MaterialIconsTwoTone-Regular.eot); /* For IE6-8 */
  src: local('Material Icons Two Tone'),
    local('MaterialIconsTwoTone-Regular'),
    url(../font/MaterialIconsTwoTone-Regular.woff2) format('woff2'),
    // url(../font/MaterialIconsTwoTone-Regular.woff) format('woff'),
    // url(../font/MaterialIconsTwoTone-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-outlined {
  @extend .material-icons;
  font-family: 'Material Icons Outlined';
}
.material-icons-round {
  @extend .material-icons;
  font-family: 'Material Icons Round';
}
.material-icons-sharp {
  @extend .material-icons;
  font-family: 'Material Icons Sharp';
}
.material-icons-two-tone {
  @extend .material-icons;
  font-family: 'Material Icons TwoTone';
}

/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

/**
README https://fonts.google.com/icons

Filled
<span class="material-icons">face</span>

Outlined
<span class="material-icons-outlined">check_circle</span>

Rounded
<span class="material-icons-round">visibility</span>

Sharp
<span class="material-icons-sharp">view_list</span>

Two Tone
<span class="material-icons-two-tone">lightbulb</span>

For browsers that do not support ligatures, fall back to specifying the icons using numeric character references like the example below:
<span class="material-icons">&#xE87C;</span>


Material icons look best at 24px, but if an icon needs to be displayed in an alternative size, using the above CSS rules can help:
<span class="material-icons md-18">face</span>
<span class="material-icons md-24">face</span>

Coloring dark fontsize
<span class="material-icons md-dark">face</span>
<span class="material-icons md-dark md-inactive">face</span>

Coloring light fontsize
<span class="material-icons md-light">face</span>
<span class="material-icons md-light md-inactive">face</span>

*/

