// out: style.min.css, compress: true, sourceMap: false, autoprefixer: last 3 versions, groupmedia: true

///////////////////////////////////////
///////////// Custum Sets & Variables ///////////////
///////////////////////////////////////

// ************ [INFO] ************* //
// Diese Dateien werden individuell angepasst
@import "custom/font-family";
@import "custom/vars";

///////////////////////////////////////
///////////// libraries ///////////////
///////////////////////////////////////
// @import "lib/aos";
@import "lib/hamburgers/hamburgers";
// @import "lib/hover";
@import "lib/simplelightbox";
@import "lib/swiper-bundle.min"; 
@import "lib/swiper";
@import "lib/pretty-checkbox/pretty-checkbox";
@import "lib/material-icons";
 
///////////////////////////////////////
//////////////// Theme ////////////////
/////////////////////////////////////// 
@import "custom/theme"; 
 
///////////////////////////////////////
/////////////// Core ///////////////
///////////////////////////////////////
@import "core/colors"; 
@import "core/utility";
@import "core/normalize";
@import "core/grid"; 
@import "core/resets";   
@import "core/forms";
@import "core/margins-paddings"; 
@import "core/font-styles";  
@import "core/blocks";  
@import "core/buttons"; 
@import "core/lists-tables";   

///////////////////////////////////////
/////////////// Basics ///////////////
///////////////////////////////////////
// @import "basics/fixed-bottom-el";
@import "basics/footer-v1";  
// @import "basics/footer-v2"; 
@import "basics/navigation-v1";
// @import "basics/obj-fit-header";  

///////////////////////////////////////
/////////////// Modules ///////////////
///////////////////////////////////////
  
// Hier sind die Module hinzuzufügen, die verwendet werden. Nichtverwendete bitte löschen
// @import "components/accordions"; 
// @import "components/accordions-responsive"; 
// @import "components/card-list-v1";
// @import "components/card-v1";     
// @import "components/content-loader";
// @import "components/css-parallax-image";
// @import "components/css-parallax";
@import "components/form-v1"; 
@import "components/form-v2"; 
@import "components/gallery";
// @import "components/img-tile";  
// @import "components/miniatures-slider";    
// @import "components/modal-v1";    
// @import "components/slider-full-bg-centred-text"; 
// @import "components/slider-v1"; 
// @import "components/split-modul";
// @import "components/tabs";
// @import "components/yt-video-v1";  


///////////////////////////////////////
/////////////// Custom ////////////////
///////////////////////////////////////
@import "custom/theme";
// Hier wird das CSS mit den eigenen Styles für die Website eingebunden z.B.:
@import "custom/main";