// out: false, main: ../style.scss
@use "../custom/vars.scss" as *;
/**
 * NOTE hier kommt der Custom CSS Code rein
 */
$himbeer: #C68AA5;
$dark-himbeer: #944875;
$warm-gray: #625D4C;
$warm-gray-hell: #908C80;
$white : #fff;
$dark-blue: #484D79;



html {
  overscroll-behavior-y: none;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $warm-gray;

  @media (min-width: 64rem) {
    font-size: 1.13rem !important;
    line-height: 2.1rem;
  }
}

::selection {
  background: #d0d0d0d5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 300;
}

p {
  font-weight: 300;
}

h2 {
  font-size: calc(1.9rem + 6 * ((100vw - 20rem)/ (1920 - 320)));
  line-height: 2rem;

  @media (min-width: 64rem) {
    font-size: calc(3.82rem + 6 * ((100vw - 20rem)/ (1920 - 320))) !important;
    line-height: 5rem;
  }
}

h3 {
  font-size: 1.25rem;
  line-height: 1rem;

  @media (min-width: 64rem) {
    font-size: 2rem !important;
    line-height: 2.25rem;
  }
}

h4 {
  font-size: 1.20rem;
  line-height: 1.6rem;

  @media (min-width: 64rem) {
    font-size: 2rem !important;
    line-height: 2.286rem;
  }
}

img {
  width: 100%;
  height: auto;
}

.antiqua {
  font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
}

.subtitle {
  font-size: 0.7rem;
  line-height: 1.417rem;
  text-transform: uppercase;

  &.fritz {
    padding: 0 1.38rem;
  }

  @media (min-width: 64rem) {
    font-size: 1.25rem;
    line-height: 2.08rem;
    letter-spacing: 1.2px;

    &.fritz {
      text-align: right;
      padding: 0 3rem 1.38rem 0;
    }
  }

  @media (min-width: 120rem) {
    &.fritz {
      padding: 0px 24rem 1.38rem 7rem;
    }
  }
}

.breite-fotos {
  margin: 2rem 0;

  @media (min-width: 64rem) {
    margin: 7rem 0;
  }
}

header {
  position: relative;

  a {
    height: inherit;

    img {
      width: auto;
      height: inherit;
    }
  }

  @media (min-width: 64rem) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;

    a {
      img {
        height: 100%;
      }
    }
  }
}

#navbar {
  // height: 10vh;
  font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;

  .logo {
    height: 4.4rem;
    display: block;
    padding: 0 0 0.6875rem 0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: $warm-gray;
    animation-name: fadeInDown;
    animation-duration: 1s;
    transition: 1s;

    @media (min-width: 64rem) {
      height: 4rem;

      .logo {
        height: 4rem;
      }
    }

    @media (min-width: 74rem) {
      height: 4.7rem;

      .logo {
        height: 4.7rem;
      }
    }
  }

  @media (min-width: 64rem) {
    .logo {
      height: 5rem;
      padding: 0;
    }
  }

  @media (min-width: 74rem) {
    .logo {
      height: 7rem;
      padding: 0;
    }
  }

}

//  @media screen and (min-width: $viewport-xl) {
//     #main-navigation {
//       display: flex;
//     }
// }
.main-navigation {

  z-index: 15;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  transition: 1s;
  width: 100%;

  /* background-color: rgba(255,255,255,.85); */
  @media (min-width: 64rem) {
    background: linear-gradient(180deg, #000 -19%, rgba(255, 255, 255, 0) 95%);

    &.kontakt {
      background: none;
    }

    &.datenschutz-impressun {
      background: $warm-gray;
    }
  }
}

@keyframes fadeInDown {
  from {
    // transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    // transform: none;
    opacity: 1;
  }
}



#hamburger {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999;
}

#main-navigation {
  display: none;

  .nav__link.active {
    color: $himbeer;
  }

  a {
    color: $warm-gray-hell;

    &:hover {
      color: $himbeer;
    }
  }

  // top: 8rem;
  padding: 0;
  background-color: $warm-gray;
  height: 100vh;

  ul {
    height: 65vh;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  @media (min-width: 64rem) {
    font-size: 1rem;
    height: inherit;

    ul {
      height: auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 74rem) {
    font-size: 1.125rem;
  }
}

@media (min-width: 64rem) {
  #hamburger {
    display: none;
  }

  #main-navigation {
    ul {
      height: 100%;
    }
  }
}

@media (min-width: 74rem) {
  #main-navigation {
    ul {
      height: 100%;
      justify-content: space-evenly;
    }
  }
}


.is-open {
  display: block !important;
}

.hamburger {
  &.is-active {

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $white;
    }
  }
}

@media (max-width: 63rem) {

  header>div.grd-row {
    background-color: $warm-gray;
  }
}


.hamburger-box {
  width: 30px;
  height: 26px;
  display: inline-block;
  position: relative;

  .hamburger-inner {

    &,
    &::before,
    &::after {
      background-color: $white;
      width: 30px;
    }
  }
}



//Swiper

.swiper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $warm-gray;
}

// .swiper-slide {

//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
//   // padding: 40px 60px;
// }

.parallax-bg {
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 130%;
  // height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide {
  font-size: 18px;
  color: $white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  picture {
    // display: inline-block;
    min-width: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    font-size: 1.80rem;
    font-weight: 300;
    font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
    line-height: 2.6rem;

    span {
      color: $himbeer;
    }
  }

  .subtitle {
    font-size: 21px;
  }

  .text {
    font-size: 0.7rem;
    width: 100%;
    line-height: 1.25rem;
  }

  @media (min-width: 64rem) {
    .text {
      font-size: 1rem;
      line-height: 1.6rem;
      // width: 30rem;
    }

    .title {
      font-size: 2.7rem;
      line-height: 3.2rem;
    }
  }

  @media (min-width: 74rem) {
    img {
      height: 100vh;
    }
  }

}

.swiper-button-next {
  right: 1rem;
}

.swiper-button-prev {
  left: 1rem;
}


.swiper-text {
  background-color: #c68aa5f6;
  padding: 2rem;

  .button--pill {
    font-size: calc(0.7rem + 1 * ((100vw - 20rem)/ (1920 - 320)));
    padding: 0.6em 3em 0.7em 1.2rem;
    color: $himbeer;
    border: 2px solid $white;
    background-color: $white;

    &:hover {
      background-color: $himbeer;
      color: $white;
      border: 2px solid $white;

      &::after {
        background: url("../img/PNG/weiß_pfeil.png") no-repeat center/9px 9px;
        top: 5px;
        right: 14px
      }
    }

    &::after {
      content: '';
      background: url("../img/PNG/himbeer_pfeil.png") no-repeat center/9px 9px;
      position: absolute;
      top: 5px;
      right: 14px;
      height: 1.5em;
      width: 1em;
      vertical-align: bottom;
      display: inline-block;
      margin: 0 0 0 0.8rem;
      padding: 0;
    }
  }

  &.first {
    background-color: #625d4c;

    .button--pill {
      color: $white;
      border: 2px solid $himbeer;
      background-color: $himbeer;
      font-size: calc(0.7rem + 1 * ((100vw - 20rem)/ (1920 - 320)));

      &:hover {
        background-color: $white;
        color: $himbeer;
        border: 2px solid $himbeer;

        &::after {
          background: url("../img/PNG/himbeer_pfeil.png") no-repeat center/9px 9px;
          top: 5px;
          right: 14px
        }
      }

      &::after {
        content: '';
        background: url("../img/PNG/weiß_pfeil.png") no-repeat center/9px 9px;
        top: 5px;
        right: 14px
      }
    }
  }

  @media (min-width: 74rem) {
    .button--pill {
      font-size: calc(1rem + 0.5 * ((100vw - 20rem)/ (1920 - 320)));
      padding: 0.9em 3em 0.9em 2rem;

      &:hover {

        &::after {
          background: url("../img/PNG/weiß_pfeil.png") no-repeat center/15px 15px;
          top: 13px;
          right: 24px;
        }
      }

      &::after {
        content: '';
        background: url("../img/PNG/himbeer_pfeil.png") no-repeat center/15px 15px;
        top: 13px;
        right: 24px;
      }
    }

    width: 35%;
    position: absolute;
    padding: 3% 6% 4%;
    bottom: 14%;
    right: 17%;

    &.first {
      background-color: $warm-gray;

      .button--pill {
        font-size: calc(1rem + 0.5 * ((100vw - 20rem)/ (1920 - 320)));

        &:hover {

          &::after {
            background: url("../img/PNG/himbeer_pfeil.png") no-repeat center/15px 15px;
            top: 13px;
            right: 24px;
          }
        }

        &::after {
          content: '';
          background: url("../img/PNG/weiß_pfeil.png") no-repeat center/15px 15px;
          top: 13px;
          right: 24px;
        }
      }
    }
  }

  // .button--pill {
  //   padding: 0.9em 3em 0.9em 2rem;
  //   border: 2px solid $white;
  //   margin-top: 0.5rem;

  // &:hover {
  //   border: 2px solid $white;

  //   &::after {
  //     background: url("../img/PNG/weiß_pfeil.png") no-repeat center/10px 10px;
  //   }
  // }

  // &::after {
  //   content: '';
  //   background: url("../img/PNG/himbeer_pfeil.png") no-repeat center/10px 10px;
  //   position: absolute;
  //   top: 13px;
  //   right: 15px;
  //   height: 1.5em;
  //   width: 1em;
  //   vertical-align: bottom;
  //   display: inline-block;
  //   margin: 0 0 0 0.8rem;
  //   padding: 0;
  // }
  // }
}

.swiper-button-next,
.swiper-button-prev {
  width: 6rem;
  height: 4.375rem;
  margin-top: -1.125rem;
  margin: 0 3rem;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Ebene_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 81.4 49'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23fff;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg id='Ebene_1-2'%3E%3Crect class='cls-1' x='.5' y='.5' width='80.4' height='48' rx='24' ry='24'/%3E%3Cpolyline class='cls-1' points='50.63 32.86 55.06 28.39 59.5 23.92 55.06 19.45 50.63 14.98'/%3E%3Cline class='cls-1' x1='58.44' y1='23.92' x2='24.5' y2='23.92'/%3E%3C/g%3E%3C/svg%3E");
  background-size: contain;

  &::after {
    content: '';
    display: none;
  }

  // &:hover {
  //   background-color: #C68AA5;
  // }
}

.swiper-button-prev {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4MS40IDQ5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6bm9uZTtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkViZW5lXzEtMiI+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIuNSIgeT0iLjUiIHdpZHRoPSI4MC40IiBoZWlnaHQ9IjQ4IiByeD0iMjQiIHJ5PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODEuNCA0OSkgcm90YXRlKC0xODApIi8+PHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIzMC43NyAzMi44NiAyNi4zNCAyOC4zOSAyMS45IDIzLjkyIDI2LjM0IDE5LjQ1IDMwLjc3IDE0Ljk4Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iMjIuOTYiIHkxPSIyMy45MiIgeDI9IjU2LjkiIHkyPSIyMy45MiIvPjwvZz48L3N2Zz4=');
  background-size: contain;

  &::after {
    content: '';
    display: none;
  }

  // &:hover {
  //   background-color: #C68AA5;
  // }
}

@media (min-width: 48rem) {

  .swiper-button-next,
  .swiper-button-prev {
    top: 24%;
  }
}

@media (min-width: 74rem) {

  .swiper-button-next,
  .swiper-button-prev {
    top: 50%;
  }
}

.header-info {
  background-color: $warm-gray;
  color: $white;
  padding-top: 3rem;

  .grd-row {
    padding: 0 1.38rem;
  }

  .grd-col {
    max-width: 462px;
  }

  .button {
    padding: 0;
  }

  .seiten-info-100-jahre {
    p {
      padding-bottom: 0;
    }

    img {
      height: auto;
    }

    padding: 0 1.38rem 2rem;

    .swiper-text-100 {
      background-color: $himbeer;
      padding: 2rem;

      &::after {
        content: '';
        background: url(../img/PNG/100-jahre.png) no-repeat center/auto 100%;
        position: absolute;
        top: 0.7rem;
        left: -1.5rem;
        height: 4.49rem;
        width: 4.49rem;
        vertical-align: bottom;
        display: inline-block;
        margin: 0 0 0 .8rem;
        padding: 0;
      }

      .button--pill {
        font-size: calc(0.7rem + 1 * ((100vw - 20rem)/ (1920 - 320)));
        padding: 0.6em 3em 0.7em 1.2rem;
        position: relative;
        color: $himbeer;
        border: 2px solid $white;
        background-color: $white;

        &:hover {
          background-color: $himbeer;
          color: $white;
          border: 2px solid $white;

          &::after {
            background: url("../img/PNG/weiß_pfeil.png") no-repeat center/9px 9px;
            top: 5px;
            right: 14px
          }
        }

        &::after {
          content: '';
          background: url("../img/PNG/himbeer_pfeil.png") no-repeat center/9px 9px;
          position: absolute;
          top: 5px;
          right: 14px;
          height: 1.5em;
          width: 1em;
          vertical-align: bottom;
          display: inline-block;
          margin: 0 0 0 0.8rem;
          padding: 0;
        }
      }

      @media (min-width: 64rem) {}

      @media (min-width: 74rem) {
        &::after {
          height: 7.9rem;
          width: 7.9rem;
          left: -4.5em;
          top: 1.49rem;
        }

        .button--pill {
          font-size: calc(1rem + 0.5 * ((100vw - 20rem)/ (1920 - 320)));
          padding: 0.9em 3em 0.9em 2rem;

          &:hover {

            &::after {
              background: url("../img/PNG/weiß_pfeil.png") no-repeat center/15px 15px;
              top: 13px;
              right: 24px;
            }
          }

          &::after {
            content: '';
            background: url("../img/PNG/himbeer_pfeil.png") no-repeat center/15px 15px;
            top: 13px;
            right: 24px;
          }
        }

        width: 40%;
        position: absolute;
        padding: 3% 6% 4%;
        bottom: 8%;
        right: 5%;
      }
    }

    @media (min-width: 74rem) {
      padding: 0px 4.9%;
    }
  }

  .nerven {
    position: relative;


  }

  .seiten-info {
    h3 {
      position: relative;
      width: fit-content;
      font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
      color: $white;
      padding-top: 3rem;

      &:hover {
        color: $himbeer;
      }

      &::after {
        content: '';
        background: url(../img/PNG/weiß_pfeil_g.png) no-repeat center/13px 13px;
        position: absolute;
        bottom: 0;
        height: 0.65em;
        width: 0.65em;
        vertical-align: middle;
        display: inline-block;
        margin: 0 0 0 0.6rem;
        padding: 0;
      }
    }

    p {
      padding-bottom: 2.5rem;
      text-transform: uppercase;
      font-size: .7rem;
      line-height: 1.417rem;
    }
  }

  @media (min-width: $viewport-m) {
    .grd-row {
      padding: 7rem 0 9rem 0;
    }

    .seiten-info {
      p {
        padding-bottom: 0;
        font-size: 1rem;
        line-height: 1.688rem;
      }
    }
  }

  @media (min-width: 64rem) {
    .nerven {
      &::after {
        height: 9rem;
        width: 9rem;
      }
    }

    .seiten-info {
      h3 {
        &::after {
          content: '';
          background: url(../img/PNG/weiß_pfeil_g.png) no-repeat center/19px 19px;
          position: absolute;
          bottom: 4px;
          height: 0.65em;
          width: 0.65em;
          vertical-align: middle;
          display: inline-block;
          margin: 0 0 0 0.6rem;
          padding: 0;
        }
      }
    }
  }
}

//Scrollen 
.scrollen {
  position: relative;

  a {
    display: block;
    padding-top: 60px;

    span {
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-animation: sdb04 2s infinite;
      animation: sdb04 2s infinite;
      box-sizing: border-box;
    }
  }

  @media (min-width: 64rem) {
    a {
      span.oben {
        left: 63%;
      }

      span.mittel {
        left: 25%;
      }

      span.unten {
        left: 63%;
      }
    }
  }

  @-webkit-keyframes sdb04 {
    0% {
      -webkit-transform: translate(0, 0);
    }

    20% {
      -webkit-transform: translate(0, -10px);
    }

    40% {
      -webkit-transform: translate(0, 0);
    }
  }

  @keyframes sdb04 {
    0% {
      transform: translate(0, 0);
    }

    20% {
      transform: translate(0, 10px);
    }

    40% {
      transform: translate(0, 0);
    }
  }


}

// #main {
//   padding-top: 8rem;
// }

.ueberschrift {
  padding: 4rem 1.38rem 2rem;
  color: $himbeer;

  h1 {
    font-size: calc(1.9rem + 6 * ((100vw - 20rem)/ (1920 - 320)));
    line-height: 2.5rem;
    text-align: right;
    padding-bottom: 1rem;
    display: flex;
  }

  @media (min-width: 64rem) {
    padding: 10rem 1.38rem 0 13rem;

    p {
      padding: 4rem 0rem 5rem 16rem;
    }

    h1 {
      font-size: calc(3.81rem + 6 * ((100vw - 20rem)/ (1920 - 320))) !important;
      line-height: 4.666rem;
      text-align: right;
      display: flex;
    }
  }
}

footer.grd-row {
  justify-content: center;
  flex-direction: column;

  @media (min-width: 64rem) {
    flex-direction: row;
    justify-content: space-between;
  }
}

footer {
  width: 100%;

  background-color: $himbeer;
  font-size: 0.80rem !important;
  line-height: 0.9rem;


  ul {
    padding: 1rem 0 2rem;
    list-style: none;
    justify-content: center;

    li {
      padding: 0 1rem;
    }

    a {
      color: $dark-himbeer;

      &:hover {
        color: $white;
      }
    }
  }

  .recht {
    padding: 1.5rem 1rem 1.5rem;

    a {
      color: $white;
      font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
      font-size: 1.479rem;
      line-height: 1.438rem;

      &:hover {
        color: $dark-himbeer;
      }
    }
  }

  @media (min-width: 64rem) {
    height: 7rem;

    .grd-row {
      justify-content: space-between;
    }

    .recht {
      padding: 0 4rem 0 0;
    }

    ul {
      padding: 0 0 0 3rem;

      li {
        padding-right: 2rem;
      }

    }
  }
}

.item1 {
  grid-area: item1;
}

.item2 {
  grid-area: item2;
  // padding: 1rem;

  // h4 {
  //   margin-bottom: 1.31em;
  // }
}

.item3 {
  grid-area: item3;
  padding: 2rem;
}

.item4 {
  grid-area: item4;
  padding: 1rem;

  h4 {
    margin-bottom: 1.31em;
  }
}

.item5 {
  grid-area: item5;
  padding: 2rem;
}



.grid-variante {

  #btn1,
  #btn2,
  #btn3,
  #btn4 {
    display: none;
  }

  #btn1,
  #btn2,
  #btn3,
  #btn4 {
    &:checked+label>img {
      opacity: 0;
    }
  }

  // img {
  //   transition: all 0.7s ease-in-out;
  //   opacity: 1;
  //   cursor: pointer;
  //   display: block;

  //   // &:hover {
  //   //   opacity: 0;
  //   // }
  //   &:checked {
  //     opacity: 0;
  //   }
  // }

  h4 {
    position: relative;
    color: $himbeer;

    &::after {
      content: '';
      background: url("../img/PNG/himbeer_pfeil_g.png") no-repeat center/12.5px 12.5px;
      position: absolute;
      bottom: 1px;
      // left: 1.5rem;
      height: 1em;
      width: 1em;
      vertical-align: bottom;
      display: inline-block;
      margin: 0 0 0 0.3rem;
      padding: 0;
    }
  }

  .item5 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    align-self: center;
    max-width: 100%;
    padding-top: 2rem;
  }

  .item3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    align-self: center;
    padding-top: 0rem;
    max-width: 100%;
  }

  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: // "item3"
  "item2"
  // "item5"
  "item4";

  @media (min-width: 32rem) {

    .item4,
    .item2 {
      width: 100%;
    }

    .item5 {
      width: 66.6666666667%;
    }

    .item3 {
      width: 66.6666666667%;
    }
  }

  @media (min-width: 48rem) {

    .item4,
    .item2 {
      width: 100%;
    }

    .item5 {
      width: 50%;
    }

    .item3 {
      width: 50%;
    }
  }

  @media (min-width: 74rem) {
    h4 {

      &::after {
        content: '';
        background: url(../img/PNG/himbeer_pfeil_g.png) no-repeat center/18px 18px;
        position: absolute;
        bottom: -9px;
        height: 1.5em;
        width: 1em;
        vertical-align: bottom;
        display: inline-block;
        margin: 0 0 0 0.3rem;
        padding: 0;
      }
    }

    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, auto);
    // grid-template-areas:
    //   ". item2 item2 item2 . . . . . . . ."
    //   ". item2 item2 item2 . . item4 item4 item4 item4 . ."
    //   ". item2 item2 item2 . . item4 item4 item4 item4 . ."
    //   ". . . . . . item4 item4 item4 item4 . .";

    // img {
    //   transition: all 0.7s ease-in-out;
    //   -webkit-transition: all 0.7s ease-in-out;
    //   opacity: 1;
    //   cursor: pointer;
    //   display: block;

    //   &:hover {
    //     opacity: 0;
    //   }
    // }


    .item2 {
      padding: 0;
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 4;
      width: 100%;
    }

    .item4 {
      padding: 0;
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 4;
      width: 100%;
    }

    .item5 {
      display: inline-grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-column-start: 7;
      grid-column-end: 11;
      grid-row-start: 2;
      grid-row-end: 5;
      align-self: center;
      width: 100%;
      height: 100%;
      padding-top: 4rem;
    }

    .item3 {
      display: inline-grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-column-start: 2;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 4;
      align-self: center;
      padding-top: 4rem;
      height: 100%;
      width: 100%;
    }

    #btn1,
    #btn2,
    #btn3,
    #btn4 {
      &:checked+label>img {
        opacity: inherit;
      }
    }
  }
}

h4 {
  position: relative;
  color: $himbeer;

  &::after {
    content: '';
    background: url("../img/PNG/himbeer_pfeil_g.png") no-repeat center/12.5px 12.5px;
    position: absolute;
    bottom: 1px;
    // left: 1.5rem;
    height: 1em;
    width: 1em;
    vertical-align: bottom;
    display: inline-block;
    margin: 0 0 0 0.3rem;
    padding: 0;
  }

  @media (min-width: 74rem) {
    &::after {
      content: '';
      background: url(../img/PNG/himbeer_pfeil_g.png) no-repeat center/18px 18px;
      position: absolute;
      bottom: -9px;
      height: 1.5em;
      width: 1em;
      vertical-align: bottom;
      display: inline-block;
      margin: 0 0 0 0.3rem;
      padding: 0;
    }
  }
}


//Unterseiten

.h1-unter-seiten {
  text-align: center;
  font-size: calc(1rem + 10 * ((100vw - 20rem)/ (1920 - 320)));
  letter-spacing: 0.5px;

  @media (min-width: 64rem) {
    font-size: calc(1.8rem + 2 * ((100vw - 20rem)/ (1920 - 320)));
  }
}

#menschen {
  background: url("../img/slide/1x/nerven-zelle.jpg") no-repeat center/cover;
  height: 50vw;
  width: 100%;

  @media (min-width: 64rem) {
    height: 65vw;
  }

  @media (min-width: 74rem) {
    height: 100vh;
  }
}

#foerderung {
  background: url("../img/slide/1x/kuenstler-malen.jpg") no-repeat center/cover;
  height: 50vw;
  width: 100%;

  @media (min-width: 64rem) {
    height: 65vw;
  }

  @media (min-width: 74rem) {
    height: 100vh;
  }
}

#zuwendungen {
  background: url("../img/slide/1x/statue-natur-aussicht.jpg") no-repeat center/cover;
  height: 50vw;
  width: 100%;

  @media (min-width: 64rem) {
    height: 65vw;
  }

  @media (min-width: 74rem) {
    height: 100vh;
  }
}

#kontakt {
  background: url("../img/slide/1x/zwei-haende.jpg") no-repeat center/cover;
  height: 50vw;
  width: 100%;

  @media (min-width: 64rem) {
    height: 65vw;
  }

  @media (min-width: 74rem) {
    height: 100vh;
  }
}



.vorstand {
  padding: 5rem 0 2rem;
  color: $himbeer;

  h2 {
    padding-bottom: 1rem;
  }

  @media (min-width: 64rem) {
    padding: 10rem 8rem 0 13rem;

    h2 {
      padding-bottom: 2rem;
    }

    p {
      padding-left: 5rem;
    }
  }
}

.vorstand-blocks {
  margin-bottom: 3rem;

  h3 {
    padding-bottom: 1rem;
  }
}

.grd-row:has(.zueruck) {
  align-items: center;
}

.zueruck {
  padding-left: 1rem;

  button {
    svg {
      width: 1.6rem;
    }

    font-family: 'TheAntiquaSunOT',
    Verdana,
    Geneva,
    Tahoma,
    sans-serif;
    color: #fff;
    border: 1px solid #fff;
    position: relative;
    width: 3.37rem;
    height: 1.7rem;
    padding: 0;

    &::after {
      content: '';
      height: 2.4em;
      width: 3em;
      vertical-align: middle;
      display: inline-block;
      margin: 0 1.3rem 0 0.8rem;
      padding: 0;
    }

    &:hover {
      background-color: $dark-himbeer;
      color: #fff;
      border: 1px solid #fff;
    }
  }

  @media (min-width: $viewport-xl) {
    padding-left: 3rem;

    button {
      svg {
        width: 2rem;
      }

      color: #fff;
      border: 1px solid #fff;
      position: relative;
      width: 9.19rem;
      height: 2.38rem;
      font-size: 12px;

      &::after {
        content: 'ZURÜCK';
        height: 3.5em;
        width: 2em;
        vertical-align: middle;
        display: inline-block;
        margin: 0 2.2rem 0 0.8rem;
        padding: 0;
        letter-spacing: 2px;
      }

      &:hover {
        background-color: $dark-himbeer;
        color: #fff;
        border: 1px solid #fff;
      }
    }
  }
}

.header {
  background-color: $himbeer;
  color: $white;

  .grd-row {
    padding-top: 1rem;
  }


  @media (min-width: $viewport-xl) {
    height: 25rem;
    margin-top: -25rem;

    .grd-row {
      padding-top: 4rem;
    }

  }
}

.foerderong {
  background-color: $white;
  color: $himbeer;

  .grd-row {
    padding-top: 1rem;
  }

  .zueruck {
    button {
      border: 1px solid $himbeer;
      color: $himbeer;
    }
  }

  @media (min-width: $viewport-xl) {
    height: 25rem;
    margin-top: -25rem;

    .grd-row {
      padding-top: 4rem;
    }
  }
}

.header-top {
  background-color: $himbeer;
  color: $white;

  // h2 {
  //   padding: 0 0 0 1rem;
  // }

  p {
    font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma, sans-serif !important;
    // padding: 0 1rem;
  }

  @media (min-width: $viewport-xl) {
    margin-top: -13rem;
    padding-bottom: 5rem;

    h2 {
      padding-bottom: 2rem;
    }

    p {
      padding: 0 4rem;
    }
  }
}

.header-historie {
  background-color: $himbeer;
  color: $white;

  .p4--tb {
    color: $himbeer;
    padding: 4rem 0rem 0;

    p {
      font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma, sans-serif;
    }
  }

  .grd-row.measure--l {
    padding: 1rem 1.38rem;
  }

  .button {
    padding: 0;
  }

  p {
    font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
  }

  .werte-text {
    flex-wrap: wrap-reverse;

    .grd-col {
      padding: 2rem 0;
    }
  }

  @media (min-width: $viewport-xl) {
    .measure--l {
      padding: 3rem 0 9rem 0;
    }

    &-text {
      display: inline-grid;
      padding: 0 4rem;

      p {
        &:last-of-type {
          font-size: 2.31rem;
          line-height: 3.1rem;
        }
      }
    }

    .p4--tb {
      padding: 10rem 0 6rem;

      h2 {
        align-self: flex-end;
        padding-bottom: 1rem
      }

      p {
        padding-right: 1rem;
        text-align: right;
      }
    }

    p {
      padding-bottom: 0;
    }

    .grd-row.measure--l {
      padding: 0 0 4rem;
    }

    .werte-text {
      .grd-col {
        padding-top: 6rem;
      }
    }
  }

  @media (min-width: 120rem) {
    &-text {
      padding: 0 7rem;
    }
  }
}

.historie-download {
  background-color: $himbeer;
  color: $white;

  h2 {
    text-align: center;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .grd-col {
    padding: 1.38rem;
    align-items: center;
  }

  .booklet {
    max-width: 310px;
  }

  .buch {
    max-width: 390px;
  }

  .grd-row {
    align-items: unset;
    // padding-bottom: 0.5rem;
  }

  button {
    font-family: 'TheAntiquaSunOT',
      Verdana,
      Geneva,
      Tahoma,
      sans-serif;
    color: #fff;
    border: 1px solid #fff;
    position: relative;
    width: 4.37rem;
    height: 2.5rem;
    padding: 0;

    svg {
      width: 1.5rem;
      padding-top: 0.34rem
    }

    &::before {
      content: '';
      /* height: 2.4em; */
      /* width: 3em; */
      vertical-align: middle;
      display: inline-block;
      /* margin: 0 1.3rem 0 0.8rem; */
      padding: 0;
    }

    &:hover {
      background-color: $dark-himbeer;
      color: #fff;
      border: 1px solid #fff;
    }
  }

  @media (min-width: $viewport-xl) {

    // h2 {
    //   align-self: center;
    // }
    h3 {
      font-size: 2rem !important;
      line-height: 2.25rem;
    }

    .grd-row {
      padding-bottom: 2rem;
    }

    .grd-col {
      padding: 0;
      justify-content: space-between;
      align-items: unset;
    }

    .header-top {
      margin-top: 0;
      padding-top: 10rem;

      h2 {
        text-align: center;
      }
    }

    .download {
      padding-left: 0;
    }

    button {
      color: #fff;
      border: 1px solid #fff;
      position: relative;
      width: 10.7rem;
      height: 2.38rem;
      font-size: 12px;

      svg {
        width: 1.9rem;
        padding-top: .35rem;
        padding-left: .7rem;
      }

      &::before {
        content: 'Downloaden';
        text-transform: uppercase;
        height: 3.5em;
        width: 2em;
        vertical-align: middle;
        display: inline-block;
        margin: 0 5.1rem 0 0.1rem;
        padding: 0;
        letter-spacing: 2px;
      }

      &:hover {
        background-color: $dark-himbeer;
        color: #fff;
        border: 1px solid #fff;
      }
    }
  }

  @media (min-width: 120rem) {
    .grd-col {
      padding: 10rem 2rem 3rem;
      justify-content: space-between;
    }
  }
}

.header-foerderong {
  background-color: $white;
  color: $himbeer;

  .header-top {
    background-color: $white;
    color: $himbeer;
    padding-bottom: 1.5rem;
  }

  .grd-row.measure--l {
    padding: 1rem 1.38rem;
  }

  .button {
    padding: 0;
  }

  @media (min-width: $viewport-xl) {
    .header-top {
      padding-bottom: 5rem;

      &.top-vorstand {
        padding-bottom: 0rem;
      }
    }

    .measure--l {
      padding: 3rem 0 9rem 0;
    }

    p {
      padding-bottom: 0;
    }

    .grd-row.measure--l {
      padding: 0 0 3rem;
    }

    .antiqua {
      padding: 4rem 1rem 4rem;
    }

    .zitat {
      padding-top: 4rem;
    }
  }
}

.header-zuwendungen {
  background-color: $himbeer;
  color: $white;

  .p4--tb {
    color: $himbeer;
    padding: 4rem 0rem 0;

    p {
      font-family: 'Open Sans', sans-serif, Verdana, Geneva, Tahoma, sans-serif;
    }
  }

  .grd-row.measure--l {
    padding: 1rem 1.38rem;
  }

  .button {
    padding: 0;
  }

  p {
    font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
  }

  .werte-text {
    flex-wrap: wrap-reverse;

    .grd-col {
      padding: 2rem 0;
    }
  }

  @media (min-width: $viewport-xl) {
    .measure--l {
      padding: 3rem 0 9rem 0;
    }

    &-text {
      display: inline-grid;
      padding: 0 4rem;

      p {
        &:last-of-type {
          font-size: 2.31rem;
          line-height: 3.1rem;
        }
      }
    }

    .p4--tb {
      padding: 10rem 0 6rem;

      h2 {
        align-self: flex-end;
        padding-bottom: 1rem
      }

      p {
        padding-right: 10rem;
        text-align: right;
      }
    }

    p {
      padding-bottom: 0;
    }

    .grd-row.measure--l {
      padding: 0 0 4rem;
    }

    .werte-text {
      .header-historie-zuwendungen-text {
        padding-bottom: 6rem;
      }

      .grd-col {
        padding-top: 6rem;
      }
    }
  }

  @media (min-width: 120rem) {
    &-text {
      padding: 0 7rem;
    }
  }
}

// #main-top {
//   .ueberschrift{

//   }
// }

.feiern-fritz {
  padding-top: 5rem;
  color: #C68AA5;

  h2 {

    // padding-left: 1.38rem;
    // margin-left: 0;
    // position: relative;
    padding-left: 1.38rem;
    margin-left: 6rem;
    position: relative;


    &::after {
      // content: '';
      // background: url(../img/PNG/100-jahre.png) no-repeat center/auto 100%;
      // position: absolute;
      // bottom: 3rem;
      // right: 1.38rem;
      // height: 5.59rem;
      // width: 5.59rem;
      // vertical-align: middle;
      // display: inline-block;
      // margin: 0 0 0 0.8rem;
      // padding: 0;
      content: '';
      background: url(../img/PNG/100-jahre.png) no-repeat center / auto 100%;
      position: absolute;
      bottom: 0rem;
      left: -5rem;
      height: 5.59rem;
      width: 5.59rem;
      vertical-align: middle;
      display: inline-block;
      /* margin: 0 0 0 .8rem; */
      padding: 0;
    }
  }

  @media (min-width: $viewport-xl) {
    .fritz {
      display: flex;
      justify-content: end;
    }

    padding-top: 10rem;

    h2 {
      padding-left: 11rem;
      padding-bottom: 1rem;
      margin-left: 0;
      // margin-left: 23rem;

      &::after {
        content: '';
        background: url(../img/PNG/100-jahre.png) no-repeat center/auto 100%;
        position: absolute;
        bottom: 2rem;
        left: 0rem;
        height: 9rem;
        width: 9rem;
        vertical-align: middle;
        display: inline-block;
        margin: 0 0 0 .8rem;
        padding: 0;
      }
    }
  }

  @media (min-width: 120rem) {

    h2 {
      padding-left: 11rem;
      padding-right: 11rem;

    }
  }
}

.button--pill {
  font-size: calc(0.7rem + 1 * ((100vw - 20rem)/ (1920 - 320)));
  padding: 0.6em 3em 0.7em 1.2rem;
  position: relative;
  color: $himbeer;
  border: 2px solid $white;
  background-color: $white;

  &:hover {
    background-color: $himbeer;
    color: $white;
    border: 2px solid $white;

    &::after {
      background: url("../img/PNG/weiß_pfeil.png") no-repeat center/9px 9px;
      top: 5px;
      right: 14px
    }
  }

  &::after {
    content: '';
    background: url("../img/PNG/himbeer_pfeil.png") no-repeat center/9px 9px;
    position: absolute;
    top: 5px;
    right: 14px;
    height: 1.5em;
    width: 1em;
    vertical-align: bottom;
    display: inline-block;
    margin: 0 0 0 0.8rem;
    padding: 0;
  }
}



.fritz-download {
  background-color: $warm-gray;
  color: $white;
  line-height: normal;

  h2 {
    text-align: center;
    color: $himbeer;
    padding: 3rem 0;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  p {
    margin: .5rem 0;
  }

  .spalten {
    border-top: 1px solid $warm-gray-hell;

    &:last-of-type {
      border-bottom: 1px solid $warm-gray-hell;
    }
  }

  .titel {
    padding-top: 2rem;

    p:first-of-type {
      color: $himbeer;
    }
  }

  .download {
    text-align: end;
  }

  button {
    font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
    font-size: calc(.6rem + 1 * ((100vw - 20rem)/ (1920 - 320)));
    padding: 1em 3em .7em .5rem;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: #c68aa5;
    color: #fff;
    border: 1px solid #c68aa5;
    position: relative;
    width: 9rem;
    height: 1.8rem;
    margin: .5rem 0 1.2rem;
    // padding: 0;

    // svg {
    //   width: 1.5rem;
    //   padding-top: 0.34rem
    // }

    &::after {
      content: '';
      background: url(../img/PNG/SVG/download.svg) no-repeat center;
      position: absolute;
      top: 5px;
      right: 14px;
      height: 1.2rem;
      vertical-align: bottom;
      display: inline-block;
      margin: 0 0 0 .8rem;
      width: 1rem;
      padding-top: .34rem;
    }

    &:hover {
      background-color: $white;
      color: $himbeer;
      border: 1px solid $himbeer;

      &::after {
        background: url("../img/PNG/SVG/download-himbeer.svg") no-repeat center;
        top: 5px;
        right: 14px
      }
    }
  }

  @media (min-width: $viewport-xl) {

    h2 {
      padding: 5rem 0;
    }

    h3 {
      font-size: 2rem !important;
      line-height: 2.25rem;
    }

    // .grd-row {
    //   padding-bottom: 1rem;
    // }

    .download {
      padding-left: 0;
    }

    .titel {
      padding: 2rem 0;
    }

    button {
      // font-size: calc(1rem + 0.5 * ((100vw - 20rem)/ (1920 - 320)));
      padding: .9em 4em .9em 1rem;
      width: 10.7rem;
      height: 2.38rem;
      font-size: 12px;
      margin: 0;

      &:hover {
        background-color: $white;
        color: $himbeer;
        border: 1px solid $himbeer;
      }

      &::after {
        content: '';
        background: url("../img/PNG/SVG/download.svg") no-repeat center;
        /* top: 13px; */
        /* right: 24px; */
        width: 1.2rem;
        height: 1.6rem;
        /* height: 1.9rem; */
        /* padding-top: .35rem; */
        padding-left: .7rem;
      }

      &:hover {

        &::after {
          background: url("../img/PNG/SVG/download-himbeer.svg") no-repeat center;
          // top: 13px;
          // right: 24px;
        }
      }
    }
  }

  // @media (min-width: 120rem) {
  //   .grd-col {
  //     padding: 10rem 2rem 3rem;
  //     justify-content: space-between;
  //   }
  // }
}

#bildgallery {
  background-color: $warm-gray;
  color: $white;
  line-height: normal;
  padding: .4rem;
  padding-top: 2rem;

  .item{
    padding: 1rem;
    &:hover {
      transform: scale(1.05);
    }
  }
  

  h2 {
    text-align: center;
    color: $himbeer;
    padding: 3rem 0;
    margin: 0;
  }

  @media (min-width: $viewport-m) {
    padding: 2.4rem;
    .item {
      padding: 2rem;
    }
  }

  // @media (min-width: $viewport-xl) {
    
  //   .item {
  //     padding: 3rem;
  //   }
  // }

  @media (min-width: 74rem) {
    padding: 4rem;
    .item {
      padding: 3rem;
    }
  }
  @media (min-width: 120rem) {
   
    .item {
      padding: 4rem;
    }
  }
}

.zitat {
  font-size: 1.5494rem;
  line-height: 1.833rem;

  &-kontakt {
    font-size: 1.2rem;
    line-height: 1.633rem;
    padding: 0 1.38rem;
    margin-bottom: 2rem;
  }

  @media (min-width: $viewport-xl) {
    font-size: 2.31rem;
    line-height: 3.1rem;

    &-kontakt {
      font-size: 1.8rem;
      line-height: 2.8rem;
      margin-bottom: 10rem;
    }
  }
}

.main-fortschrit {
  .measure--l.fortschritt {
    padding: 4rem 1.38rem 2rem;
  }

  .grd {
    padding: 0;
  }

  .fortschritt {

    p.subtitle {
      color: #C68AA5;
      padding-bottom: 1rem;
    }

    h2 {
      margin-left: 0;
      position: relative;
      color: #C68AA5;
    }

    @media (min-width: $viewport-xl) {
      >.grd-row {
        padding: 0rem 0 4rem;
      }

      h2 {
        align-self: flex-end;
      }

      p.subtitle {
        text-align: right;
        padding-right: 13rem
      }

      .grd {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .antiqua {
        padding: 0 4rem;
        align-content: start;
      }
    }

    @media (min-width: 120rem) {
      p.subtitle {
        padding: 1rem 13rem 8rem;
      }
    }

    // @media (min-width: 120rem) {

    //   h2 {
    //     margin-left: 30rem;
    //   }
    // }
  }

  @media (min-width: $viewport-xl) {
    .measure--l.fortschritt {
      padding: 10rem 0rem 2rem;
    }

    .grd-row {
      img {
        padding: 6rem 0 0rem;
      }
    }

    .zitat {
      padding-top: 4rem;
    }
  }

  @media (min-width: 120rem) {
    .grd-row {
      img {
        padding: 6rem 0 1rem;
      }
    }

    .zitat {
      padding-top: 8rem;
    }
  }
}


.main-bottom {
  .measure--l.schoenen-verbunden {
    padding: 4rem 1.38rem 2rem;
    ;
  }

  .schoenen-verbunden {

    p.subtitle {
      color: #C68AA5;
      padding-bottom: 1rem;
    }

    h2 {
      margin-left: 0;
      position: relative;
      color: #C68AA5;
    }

    @media (min-width: $viewport-xl) {
      >.grd-row {
        padding: 0rem 0 4rem;
      }

      h2 {
        align-self: flex-end;
      }

      p.subtitle {
        text-align: right;
        padding-right: 13rem;
      }

      .grd {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .antiqua {
        padding: 0 4rem
      }
    }

    @media (min-width: 120rem) {
      p.subtitle {
        padding: 1rem 13rem 8rem;
      }
    }
  }

  @media (min-width: $viewport-xl) {
    .measure--l.schoenen-verbunden {
      padding: 10rem 0rem 2rem;
      ;
    }

    .grd {
      padding: 0;
    }

    img {
      padding: 10rem 0 1rem;
    }
  }
}

.grd {
  padding-left: 1.38rem;
  padding-right: 1.38rem;

  @media (min-width: $viewport-xl) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

//Tappen
.container {
  position: relative;
  // overflow: hidden;
  padding: 0 1.38rem;

  h4 {
    cursor: pointer;
  }

  .text {
    font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
    // font-size: 36px;
    padding: 10px 20px;
    // background-color: #f1f1f1;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    // height: 100vh;
    padding: 0 1.38rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    opacity: 0;
    transition: all .6s ease-in-out;
    visibility: hidden;
    // max-width: inherit;
  }

  .text.long {
    overflow: auto;
    top: 6.5rem;
  }

  @media (min-width: 32rem) {
    padding: 0rem;

    .text {
      padding: 0;
    }
  }

  @media (min-width: $viewport-xl) {
    padding-left: 0;

    .text {
      top: 0rem;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }


    .text.down {
      top: 2.5rem;
    }

    .text.down2 {
      top: 7.3rem;
    }

    .text.long {
      overflow: auto;
      top: 6.5rem;
    }
  }

  @media (min-width: 74rem) {
    .text {
      align-items: unset;
      top: 6rem;
    }

    .text.right {
      top: 6rem;
    }

    .text.left {
      top: 6rem;
    }

    .text.down {
      top: 9.6rem;
    }

    .text.down2 {
      top: 10rem;
      overflow-x: auto;
    }
  }
}

.image-wrapper {
  position: relative;
  // z-index: 2;
}

.container {
  .text {
    font-size: 0.85rem;
    line-height: 1.52rem;

    @media (min-width: $viewport-xl) {
      font-size: 1.13rem;
      line-height: 2.1rem;
    }

    @media (min-width: 120rem) {
      font-size: 1.13rem;
      line-height: 2.7rem;
    }
  }
}

.container.show-image .image-wrapper img {
  opacity: 1;
  transition: all .6s ease-in-out;
}

.image-wrapper img {
  opacity: 0;
  transition: all .6s ease-in-out;
  padding-top: 1rem;
}

.container.show-text {
  height: 85vh;

  .text {
    opacity: 1;
    transition: all .6s ease-in-out;
    // top: 4rem;
    // height: 85vh;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0 1.38rem;
    visibility: visible;
    align-items: center;
  }

  h4 {
    position: fixed;
    top: 19vh;
    left: 0;
    z-index: 5;
    padding: 0 1.38rem;

  }

  .text.left {
    top: 2.3rem;
  }

  .text.right {
    top: 4rem;
  }

  .text.long {
    // top: 4.58rem;
    // height: 53vh;
    // height: 29rem;
    padding-top: 11rem;
  }

  .text.violinwettbewerb {
    top: 4rem;
  }

  .text.down {
    top: 3rem;
  }

  .text.down2 {
    // top: 8rem;
    overflow-x: auto;
    // height: 26rem;
    padding-top: 15rem;
  }

  .text.down3 {
    top: 2.5rem;
  }

  h4.wissenschaftspreise {
    top: 4rem;
    background: #fff;
    // height: 6rem;
    padding-top: 3rem;
    width: 100%;
  }

  h4.museen {
    top: 4rem;
    background: #fff;
    // height: 6rem;
    padding-top: 3rem;
    width: 100%;
  }

  @media (min-width: 48rem) {
    .text.long {
      // top: 4.58rem;
      // height: 53vh;
      // height: 29rem;
      padding-top: 4rem;
    }
  }


  @media (min-width: $viewport-xl) {
    height: 100%;

    h4 {
      position: relative;
      z-index: 2;
      padding: 0;
      top: 0;
    }

    h4.museen {
      position: relative;
      z-index: 2;
      padding: 0;
      height: inherit;
      width: inherit;
      top: 0;
    }

    h4.wissenschaftspreise {
      position: relative;
      z-index: 2;
      padding: 0;
      height: inherit;
      width: inherit;
      top: 0;
    }

    .text {
      height: 100%;
      background-color: unset;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .text.left {
      top: -1.7rem;
    }

    .text.right {
      top: 1.2rem;
    }

    .text.down {
      top: 0;
    }

    .text.down2 {
      top: 2rem;
      height: inherit;
      padding-top: 0;
    }

    .text.long {
      height: 100%;
      padding-top: 1.4rem;
      top: 3rem;
    }

    .text.down3 {
      top: inherit;
    }
  }

  @media (min-width: 74rem) {
    .text {
      align-items: unset;
      top: 6rem;
    }

    .text.right {
      top: 6rem;
    }

    .text.left {
      top: 6rem;
    }

    .text.down {
      top: 9.6rem;
    }

    .text.down2 {
      top: 10rem;
    }

    .text.long {
      top: 6rem;
    }
  }
}

/* ... Dein vorhandener CSS-Code ... */

// .hint {
//   position: absolute;
//   bottom: 10px;
//   left: 50%;
//   transform: translateX(-50%);
//   background-color: rgba(0, 0, 0, 0.7);
//   color: #fff;
//   font-size: 14px;
//   padding: 5px 10px;
//   border-radius: 5px;
//   opacity: 0;
//   pointer-events: none;
//   transition: opacity 0.3s ease;
// }

// .container.show-hint .hint {
//   opacity: 1;
//   pointer-events: auto;
// }

.foerderong-intiative {
  padding: 0 1.38rem;

  .p4--tb {
    color: $himbeer;
    padding: 4rem 0 0;
  }

  @media (min-width: $viewport-xl) {
    padding: 0rem;

    .subtitle {
      padding-left: 8rem;
    }

    .grd-row.measure--l {
      padding: 0 0 3rem;
    }

    .p4--tb {
      padding: 10rem 0 0 7rem;
    }

    .antiqua {
      padding-bottom: 4rem;
    }

    .grd-align--align-center:has(.antiqua) {
      padding: 4rem 1rem 0rem;
      align-items: end;
    }

  }

  @media (min-width: 120rem) {
    p.subtitle {
      padding: 1rem 0rem 8rem 13rem
    }

    .antiqua {
      padding-bottom: 6rem;
    }

    .grd-align--align-center:has(.antiqua) {
      padding: 4rem 1rem 0rem;
      align-items: end;
    }
  }
}

//datenschutz

.datenschutz {
  color: $warm-gray;

  h4 {
    &::after {
      content: '';
      background: none;
    }
  }

  h1 {
    color: $himbeer;
    text-align: left;
  }

  p {
    padding: 0 0 11px !important;
  }

  @media (min-width: $viewport-xl) {
    padding-top: 15rem;

    p {
      padding: 0 0 2rem !important;

      &:last-of-type {
        padding-bottom: 8rem !important;
      }
    }
  }

}



.sl-wrapper .simple-lightbox {
  font-family: 'TheAntiquaSunOT', Verdana, Geneva, Tahoma, sans-serif;
}
.sl-wrapper .sl-navigation button.sl-prev, .sl-wrapper .sl-navigation button.sl-next {
  text-indent: -9999999px;
}

.mein-element, .mein-element1 {
  opacity: 0;
  transition: opacity .7s ease-in-out, transform .7s ease-in-out;
  transition-delay: 0.250ms;
  transform: translateY(5rem)
  
}

.visible {
  opacity: 1;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: afb !important;
  animation-delay: 0.250ms !important;
 
}

@keyframes afb {
  from {
      opacity: 0;
      transform: translateY(5rem)
  }

  to {
      opacity: 1;
      transform: translateY(0)
  }
}